import React from "react"

const SplitTitle = ({ primaryTitle }) => {
  return (
    <section>
      {/* Quote Title*/}
      <div className="xl:container mx-auto ">
        <div className="font-bold leading-tight uppercase text-center py-2 mx-auto text-base md:text-lg">
          {primaryTitle}
        </div>
      </div>
    </section>
  )
}

export default SplitTitle
