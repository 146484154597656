import React from "react"

const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      id="Path_2655"
      data-name="Path 2655"
      d="M38.556,3H7.444A4.458,4.458,0,0,0,3,7.444V38.556A4.458,4.458,0,0,0,7.444,43H38.556A4.458,4.458,0,0,0,43,38.556V7.444A4.458,4.458,0,0,0,38.556,3Zm0,35.556H7.444V7.444H38.556ZM36.311,16.333l-3.133-3.156L18.533,27.822,12.8,22.111,9.644,25.244l8.889,8.867Z"
      transform="translate(-3 -3)"
      fill="#fff"
    />
  </svg>
)

export default SVGComponent
