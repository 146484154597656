import React, { Component } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FormattedHTMLMessage } from "gatsby-plugin-intl"
// fragments imports
import MorningGreenTitle from "./fragments/MorningGreenTitle"
import SplitMorningCoffeeTitle from "./fragments/SplitMorningCoffeeTitle"
import Card from "./fragments/Card"

// image imports
import AppstoreLogo from "../assets/homepage/04-sensore/appstore.png"
import GoggleplayLogo from "../assets/homepage/04-sensore/googleplay.png"
import EnerthingLogo from "../assets/homepage/04-sensore/enerthing.png"
import BluetoothLogo from "../assets/homepage/04-sensore/bluetooth_logo.png"
import AreusLogo from "../assets/homepage/04-sensore/areus-logo.png"
import LightingLogo from "../assets/homepage/04-sensore/lighting_solutions.png"
import AirMobile from "../assets/homepage/04-sensore/mobile-app-relution-air.png"
import EnerthingSensor from "../assets/homepage/04-sensore/enerthing-sensor-device.png"
import AreusSensor from "../assets/homepage/04-sensore/areus-sensor-device.png"
import Blue2lightSensor from "../assets/homepage/04-sensore/multisensor-relution-air-devices.png"

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1396,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <div className=" lg:bg-white xl:container mx-auto items-center pb-10 px-8 sm:px-10">
        <div className="lg:flex lg:flex-cols justify-between xl:mb-3 ">
          {/* 01 - green title */}
          <div className="lg:bg-white py-5 lg:pb-0 w-10/12 hidden xs:block">
            <MorningGreenTitle
              firstRowTitle={
                <span className=" bg-morning_green w-max px-2 hidden sm:block">
                  <FormattedHTMLMessage id="homepage-perfect-collection.title-row-01" />
                </span>
              }
              secundRowTitle={
                <span className="bg-morning_green w-max px-2">
                  <FormattedHTMLMessage id="homepage-perfect-collection.title-row-02" />
                </span>
              }
              tertiaryRowTitle={
                <span className="bg-morning_green w-max px-2 sm:w-9/12">
                  <FormattedHTMLMessage id="homepage-perfect-collection.title-row-03" />
                </span>
              }
            />
          </div>
          {/* 02 - gray tag*/}

          <Card className="hidden lg:block w-2/12">
            <h6
              className="uppercase text-center font-bold bg-bg_coffee 
            text-sm md:py-10 my-5 sm:mt-5 px-7"
            >
              <FormattedHTMLMessage id="homepage-perfect-collection.title-tag" />
            </h6>
          </Card>
        </div>
        <div className="">
          {/* ItemsCard {id-homepage-perfect-collection} */}
          <Slider {...settings} className="lg:my-10 bg-white">
            <div className=" text-center pb-5">
              {/* 01 Item */}
              <Card className="px-5 xl:px-10">
                <h4 className="md:text-lg font-bold leading-tight uppercase text-center mt-8 mb-5 mx-auto">
                  <FormattedHTMLMessage id="homepage-perfect-collection.title-app" />
                </h4>
                <div className="pb-5">
                  <img
                    src={AirMobile}
                    alt="Relution Air Mobile Application"
                    className="mx-auto"
                  />
                </div>
                {/* Title */}
                <div>
                  <div className="mx-4 border-t-4 border-b-4 text-xl uppercase">
                    <div className="">
                      {/* Quote Title*/}
                      <SplitMorningCoffeeTitle
                        primaryTitle={
                          <h5>
                            <FormattedHTMLMessage id="homepage-perfect-collection.title-mobile-app" />
                          </h5>
                        }
                      />
                    </div>
                  </div>

                  <div className=" w-full sm:h-64 lg:h-72">
                    <p className="mt-3 text-xs sm:text-sm lg:text-base font-light leading-relaxed space-x-1 pb-5 ">
                      <FormattedHTMLMessage id="homepage-perfect-collection.text-mobile-app" />
                    </p>
                  </div>
                </div>
                {/* images */}
                <div className="flex justify-between border-b border-t py-1 mt-0.5 mx-4">
                  <div className="mx-auto flex flex-row w-auto h-auto py-2">
                    <a
                      href="https://apps.apple.com/de/app/relution-air/id1600460366?l=de"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={AppstoreLogo}
                        className="max-w-xs h-5 xs:h-6 sm:h-8 pr-2"
                        alt="App store Logo"
                      ></img>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.mwaysolutions.relution_air&gl=DE"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={GoggleplayLogo}
                        className="max-w-xs h-5 xs:h-6 sm:h-8"
                        alt="Goggle play Store Logo"
                      ></img>
                    </a>
                  </div>
                </div>
              </Card>
            </div>

            {/* Item 02 */}
            <div className="text-center pb-5">
              <Card className="px-5 xl:px-10">
                <h4 className="md:text-lg font-bold leading-tight uppercase text-center mt-8 mb-5 mx-auto">
                  {" "}
                  <FormattedHTMLMessage id="homepage-perfect-collection.title-photovoltaics" />
                </h4>
                <div className="pb-5">
                  <img
                    src={EnerthingSensor}
                    alt="Enerthing Sensor Device"
                    className="mx-auto"
                  />
                </div>
                {/* Title */}
                <div>
                  <div className="mx-4 border-t-4 border-b-4 text-xl uppercase">
                    <div className="">
                      {/* Quote Title*/}
                      <SplitMorningCoffeeTitle
                        primaryTitle={
                          <h5>
                            {" "}
                            <FormattedHTMLMessage id="homepage-perfect-collection.title-enerthing-sensor" />
                          </h5>
                        }
                      />
                    </div>
                  </div>

                  <div className=" w-full sm:h-64 lg:h-72">
                    <p className="mt-3 text-xs sm:text-sm lg:text-base font-light leading-relaxed pb-5 mx-2">
                      <FormattedHTMLMessage id="homepage-perfect-collection.text-enerthing-sensor" />
                    </p>
                  </div>
                </div>

                {/* images */}
                <div className="flex justify-between border-b border-t py-1 mt-0.5 mx-4">
                  <div className="mx-auto flex flex-row w-auto h-auto py-2">
                    <img
                      src={EnerthingLogo}
                      className="max-w-xs h-5 xs:h-6 sm:h-8 pr-2"
                      alt="Enerthing Logo"
                    ></img>
                    <img
                      src={BluetoothLogo}
                      className="max-w-xs h-5 xs:h-6 sm:h-8"
                      alt="Bluetooth Logo"
                    ></img>
                  </div>
                </div>
                <p className="text-xs font-bold leading-tight uppercase text-center text-morning_green py-2">
                  <FormattedHTMLMessage id="homepage-perfect-collection.available-preseries" />
                </p>
              </Card>
            </div>
            {/* Item 03 */}
            <div className=" text-center pb-5 ">
              <Card className="px-5 xl:px-10">
                <h4 className="md:text-lg font-bold leading-tight uppercase text-center mt-8 mb-5 mx-auto ">
                  <FormattedHTMLMessage id="homepage-perfect-collection.title-long-battery" />
                </h4>
                <div className="pb-5">
                  <img
                    src={AreusSensor}
                    alt="Areus Sensor Device"
                    className="mx-auto"
                  />
                </div>
                {/* Title */}
                <div>
                  <div className="mx-4 border-t-4 border-b-4 text-xl uppercase">
                    <div className="">
                      {/* Quote Title*/}
                      <SplitMorningCoffeeTitle
                        primaryTitle={
                          <h5>
                            <FormattedHTMLMessage id="homepage-perfect-collection.title-areus-sensor" />
                          </h5>
                        }
                      />
                    </div>
                  </div>

                  <div className=" w-full sm:h-64 lg:h-72">
                    <p className="mt-3 text-xs sm:text-sm lg:text-base font-light leading-relaxed space-x-1 pb-5 mx-2">
                      <FormattedHTMLMessage id="homepage-perfect-collection.text-areus-sensor" />
                    </p>
                  </div>
                </div>

                {/* images */}
                <div className="flex justify-between border-b border-t py-1 mt-0.5 mx-4">
                  <div className="mx-auto flex flex-row w-auto h-auto py-2">
                    <img
                      src={AreusLogo}
                      className="max-w-xs h-5 xs:h-6 sm:h-8 pr-2"
                      alt="Areus Logo"
                    ></img>
                    <img
                      src={BluetoothLogo}
                      className="max-w-xs h-5 xs:h-6 sm:h-8"
                      alt="Bluetooth Logo"
                    ></img>
                  </div>
                </div>
                <p className="text-xs font-bold leading-tight uppercase text-center text-morning_green py-2">
                  {" "}
                  <FormattedHTMLMessage id="homepage-perfect-collection.available-preseries" />
                </p>
              </Card>
            </div>
            {/* item04 */}
            <div className="text-center pb-5">
              <Card className="px-5 xl:px-10">
                <h4 className="md:text-lg font-bold leading-tight uppercase text-center mt-8 mb-5 mx-auto ">
                  <FormattedHTMLMessage id="homepage-perfect-collection.title-maintenance-free" />
                </h4>
                <div className="pb-5">
                  <img
                    src={Blue2lightSensor}
                    alt="Blue2light Multisensor Relution Air Devices"
                    className="mx-auto"
                  />
                </div>
                {/* Title */}
                <div>
                  <div className="mx-4 border-t-4 border-b-4 text-xl uppercase">
                    <div className="">
                      {/* Quote Title*/}
                      <SplitMorningCoffeeTitle
                        primaryTitle={
                          <h5>
                            <FormattedHTMLMessage id="homepage-perfect-collection.title-blu-light" />
                          </h5>
                        }
                      />
                    </div>
                  </div>

                  <div className=" w-full sm:h-64 lg:h-72">
                    <p className="mt-3 text-xs sm:text-sm lg:text-base font-light leading-relaxed space-x-1 pb-5 mx-2">
                      <FormattedHTMLMessage id="homepage-perfect-collection.text-blu-light" />
                    </p>
                  </div>
                </div>

                {/* images */}
                <div className="flex justify-between border-b border-t py-1 mt-0.5 mx-4">
                  <div className="mx-auto flex flex-row w-auto h-auto py-2">
                    <img
                      src={LightingLogo}
                      className="max-w-xs h-5 xs:h-6 sm:h-8 pr-2"
                      alt="Lighting Logo"
                    ></img>
                    <img
                      src={BluetoothLogo}
                      className="max-w-xs h-5 xs:h-6 sm:h-8"
                      alt="Bluetooth Logo"
                    ></img>
                  </div>
                </div>
              </Card>
            </div>
          </Slider>
        </div>
      </div>
    )
  }
}
