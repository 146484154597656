import React from "react"
import { FormattedHTMLMessage, useIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
// images imports
import ImgSection from "../components/assets/homepage/02-mobile-app/die_mobile_app_image_section.png"
import IconCheck from "../components/assets/svg /IconCheck"
import RelutionIcon from "../components/assets/svg /RelutionIcon"
import BlueLight from "../components/assets/homepage/07-luftqualitat/blue-light.png"
import Sensor from "../components/assets/homepage/07-luftqualitat/sensor.png"
// templates imports
import TableCardSection from "../components/templates/TableCardSection"
import ImageCardSection from "../components/templates/ImageCardSection"
import SlideItems from "../components/templates/SlideItems"
import AmplelSlider from "../components/templates/AmpelSlider"
// fragments imports
import FloatHorizontalSlot from "../components/templates/fragments/FloatHorizontalSlot"
import HorizontalCardSlot from "../components/templates/fragments/HorizontalCardSlot"
import Card from "../components/templates/fragments/Card"
import MorningGreenTitle from "../components/templates/fragments/MorningGreenTitle"
import SplitMorningCoffeeTitle from "../components/templates/fragments/SplitMorningCoffeeTitle"

const IndexPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "title" })}
        keywords={[`Relution AIR`, `application`, `M-Way`]}
        translate="no"
      />
      {/* id-homepage-advantages - formatted message */}
      <FloatHorizontalSlot
        // image + text
        firstSlot={
          <Card className="object-cover md:w-2/4 object-right relative sm:w-full ">
            <img
              src={ImgSection}
              alt="Relution Air"
              className="w-full h-full float-left object-cover"
            ></img>

            <div className="text-left absolute right-28 xs:right-10 tracking-wider mt-10 xxl:mt-20 ">
              <MorningGreenTitle
                firstRowTitle={
                  <span className="bg-morning_green w-max px-2" translate="no">
                    <FormattedHTMLMessage id="homepage-advantages.relution-air-morning-green-title" />
                  </span>
                }
                secundRowTitle={
                  <span className="bg-morning_green w-max px-2">
                    {/* <FormattedHTMLMessage id="homepage-advantages.relution-air-mobile-morning-green-title" /> */}
                    <FormattedHTMLMessage id="homepage-advantages.relution-air_vorteile" />
                  </span>
                }
              />
            </div>
          </Card>
        }
        // green bg + text
        secundSlot={
          <Card className="w-full md:w-2/4 bg-morning_green object-left text-center mx-auto ">
            <div className="sm:container mx-auto uppercase text-white leading-relaxed py-10 xxl:pt-20">
              <div
                className=" font-bold items-stretch mx-auto
                text-sm md:text-lg 2xl:text-2xl w-9/12 sm:w-11/12 md:w-2/3"
              >
                <div className="xxl:py-5">
                  <IconCheck className="transform scale-75 mx-auto" />
                  <p className="pb-3 lg:pb-8 xl:pb-10 pt-2 px-3 xl:px-0 mx-auto xxl:w-6/12">
                    <FormattedHTMLMessage id="homepage-advantages.vorteile-01" />
                  </p>
                </div>
                <div className="xxl:py-5">
                  <IconCheck className="transform scale-75 mx-auto" />
                  <p className="pb-3 lg:pb-8 xl:pb-10  pt-2 px-3 xl:px-0 mx-auto xxl:w-6/12">
                    <FormattedHTMLMessage id="homepage-advantages.vorteile-02" />
                  </p>
                </div>
                <div className="xxl:py-5">
                  <IconCheck className="transform scale-75 mx-auto" />
                  <p className="pb-3 lg:pb-8 xl:pb-10 pt-2 px-3 xl:px-0 mx-auto xxl:w-6/12">
                    <FormattedHTMLMessage id="homepage-advantages.vorteile-03" />
                  </p>
                </div>
              </div>
            </div>
          </Card>
        }
      />
      {/* id-homepage-mobile-ampel - formatted message */}
      {/* <MobileAmpelCardSection /> */}
      <AmplelSlider />
      <div className="bg-bg_coffee w-full h-10 mx-auto hidden lg:block"></div>
      {/* id-homepage-perfect-collection - formatted message */}
      <SlideItems />
      {/* id-advantages-overview - formatted message */}
      <TableCardSection />
      {/* id-relution-air-school - formatted message */}
      <ImageCardSection />
      {/* /////////////////////////// RELUTION AIR VERBESSERT DIE LUFTQUALITÄT /////////////////////////// */}
      {/* id-improve-air-quality - formatted message */}
      <div className="bg-white xl:container mx-auto -mt-6 md:-mt-0 ">
        <div className="hidden md:block md:pt-10 px-10">
          <MorningGreenTitle
            firstRowTitle={
              <span
                className="bg-morning_green uppercase px-2 w-max"
                translate="no"
                lang="de"
              >
                <FormattedHTMLMessage id="homepage-advantages.relution-air-morning-green-title" />
              </span>
            }
            secundRowTitle={
              <span className="bg-morning_green uppercase px-2 w-max ">
                <FormattedHTMLMessage id="homepage-improve-air-quality.title-row-02" />
              </span>
            }
          />
        </div>
        {/* 01 section  */}
        <HorizontalCardSlot
          primaryRow={
            <div className="md:px-10">
              <Card className="pt-5 pb-5 xl:pb-0">
                <div className="relative">
                  <div className="mt-1 lg:mt-0 xl:py-0 section-bg h-96 max-w-full">
                    <div className="absolute sm:bottom-10 top-10 block md:hidden px-5 md:px-0">
                      <MorningGreenTitle
                        firstRowTitle={
                          <span
                            className="bg-morning_green uppercase px-2 w-max"
                            translate="no"
                            lang="de"
                          >
                            <FormattedHTMLMessage id="homepage-advantages.relution-air-morning-green-title" />
                          </span>
                        }
                        secundRowTitle={
                          <span className="bg-morning_green uppercase px-2 w-max ">
                            <FormattedHTMLMessage id="homepage-improve-air-quality.title-row-02" />
                          </span>
                        }
                      />
                    </div>
                    <div className="absolute -left-0 bottom-1 transform scale-50 ">
                      <RelutionIcon />
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          }
          /* id-improve-air-quality - formatted message */
          secundaryRow={
            <Card className="xl:pr-10 md:px-10 px-5 pt-5">
              <div className="w-full ">
                <div className="sm:text-left ">
                  <p
                    className="font-bold s:uppercase sm:pb-3
                    2xl:text-lg lg:text-xl xl:text-base text-sm hidden sm:block"
                  >
                    <FormattedHTMLMessage id="homepage-improve-air-quality.card-intro" />
                  </p>
                  <h3 className="2xl:text-3xl xl:text-xl md:text-3xl text-base font-bold uppercase leading-relaxed pb-5 sm:pb-10">
                    <FormattedHTMLMessage id="homepage-improve-air-quality.card-title" />
                  </h3>
                  <p
                    className="font-light leading-relaxed pb-10 md:pb-20 text-left 
                    xxl:font-base 2xl:text-xl lg:text-xl xl:text-base"
                  >
                    <FormattedHTMLMessage id="homepage-improve-air-quality.card-text" />
                  </p>
                </div>
              </div>
            </Card>
          }
        />
        <div className="xl:py-5 xl:container mx-auto bg-white"></div>

        {/* 02 section  */}
        <HorizontalCardSlot
          /* id-energy-measurement - formatted message  */
          primaryRow={
            <Card className="px-5 md:px-10">
              <div className="w-full h-5/6 ">
                <div className="sm:text-left ">
                  <p
                    className="font-bold s:uppercase pb-3 
                    2xl:text-lg lg:text-xl xl:text-base text-sm hidden sm:block"
                  >
                    <FormattedHTMLMessage id="homepage-energy-measurement.card-intro" />
                  </p>
                  <h3 className="2xl:text-3xl xl:text-xl md:text-3xl text-base font-bold uppercase leading-relaxed pb-5 sm:pb-10">
                    <FormattedHTMLMessage id="homepage-energy-measurement.card-title" />
                  </h3>
                  <p
                    className="font-light leading-relaxed pb-5 text-left 
                   xxl:font-base 2xl:text-xl lg:text-xl xl:text-base "
                  >
                    <FormattedHTMLMessage id="homepage-energy-measurement.card-text" />
                  </p>
                </div>
              </div>
            </Card>
          }
          secundaryRow={
            <div className="xl:pr-10 md:px-10 hidden xl:block">
              <Card className="w-full h-96 block-bg"></Card>
            </div>
          }
        />
        {/* 03 section  */}
        <div className="sm:py-10 xl:container mx-auto bg-white"></div>
        {/* id-convenient-use - formatted message */}
        <HorizontalCardSlot
          primaryRow={
            <div className="xl:px-10 md:px-10 px-5 py-10 md:py-0">
              <Card>
                <div>
                  <div>
                    <img src={Sensor} alt="Blue2light device" />
                  </div>

                  <div>
                    <p
                      className="font-medium s:font-bold uppercase text-left
                    2xl:text-lg lg:text-xl xl:text-base text-sm"
                    >
                      <FormattedHTMLMessage id="homepage-convenient-use.card-intro-01" />
                    </p>
                  </div>
                </div>
              </Card>
            </div>
          }
          secundaryRow={
            <div className="xl:pr-10 md:px-10 px-5 ">
              <Card>
                <div className="">
                  <div>
                    {" "}
                    <img src={BlueLight} alt="C02 Sensor Device" />
                  </div>
                  <div>
                    <p
                      className=" font-medium s:font-bold uppercase text-left
                    2xl:text-lg lg:text-xl xl:text-base text-sm"
                    >
                      <FormattedHTMLMessage id="homepage-convenient-use.card-intro-02" />
                    </p>
                  </div>
                </div>
              </Card>
            </div>
          }
        />
        {/* 04 section  */}

        <HorizontalCardSlot
          primaryRow={
            <Card className="xl:px-10 md:px-10 px-5 pt-3 md:pt-0">
              <div className="py-10">
                <h3 className="2xl:text-3xl xl:text-xl md:text-3xl text-base font-bold uppercase leading-relaxed pb-5 sm:pb-10 ">
                  <FormattedHTMLMessage id="homepage-convenient-use.card-title" />
                </h3>

                <p
                  className="font-light leading-relaxed xl:pb-20 text-left 
             xxl:font-base 2xl:text-xl lg:text-xl xl:text-base "
                >
                  <FormattedHTMLMessage id="homepage-convenient-use.card-text" />
                </p>
              </div>
            </Card>
          }
          secundaryRow={
            <div className="xl:pr-10 ">
              <div className="mx-auto pt-5 xl:pt-28 pb-5 ">
                <SplitMorningCoffeeTitle
                  primaryTitle={
                    <div
                      className="mx-auto py-3text-center py-3 border-t-2 border-b-2 md:border-t-4 md:border-b-4 
                                    text-xs md:text-xl md:px-10 mb-5 xl:text-center w-5/6 lg:w-3/6 sm:w-3/6"
                    >
                      <FormattedHTMLMessage id="homepage-convenient-use.split-title" />
                    </div>
                  }
                />
              </div>
            </div>
          }
        />
      </div>
      {/* ///////////////////////////END OF RELUTION AIR VERBESSERT DIE LUFTQUALITÄT /////////////////////////// */}
      <div className="xl:py-7"></div>
    </Layout>
  )
}

export default IndexPage
