import React from "react"

const ImgTextSection = ({ firstSlot, secundSlot }) => {
  return (
    <section>
      <div className="w-full flex flex-wrap object-fill xl:container mx-auto">
        {firstSlot}
        {secundSlot}
      </div>
    </section>
  )
}

export default ImgTextSection
