import React from "react"
import { FormattedHTMLMessage } from "gatsby-plugin-intl"
// fragments imports
import SplitMorningCoffeeTitle from "./fragments/SplitMorningCoffeeTitle"
import MorningGreenTitle from "./fragments/MorningGreenTitle"
// images imports
import RelutionSectionImage from "../assets/homepage/06-gute-luft-sulraumen/good-air-classrooms.png"
import IncreasedConcentration from "../assets/homepage/06-gute-luft-sulraumen/increased-concentration.png"
import ProtectiveMeasures from "../assets/homepage/06-gute-luft-sulraumen/protective-measures.png"
import EfficientVentilation from "../assets/homepage/06-gute-luft-sulraumen/efficient-ventilation.png"
import Card from "./fragments/Card"

const ImageCardSection = () => {
  return (
    <section className="sm:py-10 hidden md:block">
      <div className="xl:container mx-auto bg-white ">
        <div className="px-10 pt-8 pb-4">
          <MorningGreenTitle
            firstRowTitle={
              <span className="bg-morning_green px-2 w-max ">
                <FormattedHTMLMessage id="homepage-relution-air-school.title-row-01" />
              </span>
            }
            secundRowTitle={
              <span className="bg-morning_green px-2 w-max">
                <FormattedHTMLMessage id="homepage-relution-air-school.title-row-02" />
              </span>
            }
          />
        </div>

        <div className="md:px-10 md:pt-0">
          <div
            className="w-6/12 text-center font-bold text-white 
          sm:text-lg md:text-4xl 2xl:text-5xl "
          ></div>
          <div className="relative">
            <img
              src={RelutionSectionImage}
              alt="Relution Air - Gute Luft - Wichtig in Schulräumen"
              className="w-full h-auto"
            />

            <div
              className="md:absolute mx-auto
            w-5/6 md:w-3/6 lg:w-1/3 xxl:w-1/4 md:bottom-2 sm:bottom-0 right-10 xxs:pt-10 md:pt-0 "
            >
              <SplitMorningCoffeeTitle
                primaryTitle={
                  <div className="text-center py-3 md:text-white border-t-4 border-b-4 md:border-white md:px-10">
                    <FormattedHTMLMessage id="homepage-relution-air-school.split-title" />
                  </div>
                }
              />
            </div>
          </div>
          <div className="xl:container mx-auto text-left object-fill">
            <div className="flex flex-col bg-white mt-8 sm:flex-row sm:-mx-3  ">
              {/* 01 Image Card */}
              <div className="sm:pr-4 sm:pl-3">
                <Card className="mb-8">
                  <img
                    src={IncreasedConcentration}
                    alt="Increased concentration through fresh air"
                    className="lg:h-40 xl:h-52 2xl:h-64 xxl:h-80 w-full"
                  />
                  <p
                    className=" font-bold leading-tight py-3 uppercase text-center md:text-left 
                 xxl:text-2xl 2xl:text-lg lg:text-xl xl:text-sm sm:text-sm"
                  >
                    <FormattedHTMLMessage id="homepage-relution-air-school.card-01" />
                  </p>
                </Card>
              </div>
              {/* 02 Image Card */}
              <div className="sm:px-5">
                <Card className="mb-8">
                  <img
                    src={ProtectiveMeasures}
                    alt="Protective measures to prevent infection"
                    className="lg:h-40 xl:h-52 2xl:h-64 xxl:h-80 w-full"
                  />
                  <p
                    className="font-bold leading-tight py-3 uppercase text-center md:text-left
                  xxl:text-2xl 2xl:text-lg lg:text-xl xl:text-sm sm:text-sm"
                  >
                    <FormattedHTMLMessage id="homepage-relution-air-school.card-02" />
                  </p>
                </Card>
              </div>
              {/* 03 Image Card */}
              <div className="sm:px-4">
                <Card className="mb-8">
                  <img
                    src={EfficientVentilation}
                    alt="Efficient ventilation for energy conservation"
                    className="lg:h-40 xl:h-52 2xl:h-64 xxl:h-80 w-full"
                  />
                  <p
                    className="font-bold leading-tight py-3 uppercase text-center md:text-left
                xxl:text-2xl 2xl:text-lg lg:text-xl xl:text-sm sm:text-sm"
                  >
                    <FormattedHTMLMessage id="homepage-relution-air-school.card-03" />
                  </p>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ImageCardSection
