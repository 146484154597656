import React, { Component } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FormattedHTMLMessage } from "gatsby-plugin-intl"
// fragments imports
import SplitMorningCoffeeTitle from "./fragments/SplitMorningCoffeeTitle"
import Card from "./fragments/Card"

import AmpelGrun from "../assets/homepage/03-mobile-ampel/ampel-green.png"
import AmpelOrange from "../assets/homepage/03-mobile-ampel/ampel-orange.png"
import AmpelRot from "../assets/homepage/03-mobile-ampel/ampel-rot.png"

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1396,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <div className="bg-white md:px-5 xl:container xl:px-0 mx-auto items-center pt-10 lg:pt-16">
        <div className="px-5 md:px-5 xl:ml-5">
          <h3 className="text-lg md:text-2xl font-bold leading-tight uppercase mx-auto">
            <FormattedHTMLMessage id="homepage-mobile-ampel.mobile-ampel-morning-coffee-title" />
          </h3>
          <p
            className="text-sm md:text-lg 2xl:text-2xl font-light mx-auto
                        leading-relaxed mt-4 sm:w-full"
          >
            <FormattedHTMLMessage id="homepage-mobile-ampel.mobile-ampel-intro" />
          </p>
        </div>
        <Slider {...settings} className="md:mt-10">
          {/* ******************************** 01 *************************************** */}

          <Card className="bg-white">
            <img
              src={AmpelGrun}
              alt="Mobile Interface - Ampel Green"
              className="items-center m-auto max-w-xs min-h-min 
                         lg:max-w-full lg:max-h-fit "
            />
            <h3 className=" text-center font-bold leading-tight uppercase md:text-xl">
              <FormattedHTMLMessage id="homepage-mobile-ampel.mobile-ampel-green" />
            </h3>
            <p className="mt-4 text-sm md:text-lg 2xl:text-2xl font-light leading-relaxed text-center px-8">
              <FormattedHTMLMessage id="homepage-mobile-ampel.mobile-ampel-green-text" />
            </p>
          </Card>

          {/* *********************************** 02 ************************************* */}

          <Card className="bg-white">
            <img
              src={AmpelOrange}
              alt="Mobile Interface - Ampel Orange"
              className="items-center m-auto max-w-xs min-h-min lg:max-w-full lg:max-h-fit"
            />
            <h3 className=" text-center font-bold leading-tight uppercase md:text-xl">
              <FormattedHTMLMessage id="homepage-mobile-ampel.mobile-ampel-orange" />
            </h3>
            <p className="mt-4 text-sm md:text-lg 2xl:text-2xl font-light leading-relaxed text-center px-8">
              <FormattedHTMLMessage id="homepage-mobile-ampel.mobile-ampel-orange-text" />
            </p>
          </Card>

          {/* ********************************* 03 ****************************************** */}

          <Card className=" bg-white">
            <img
              src={AmpelRot}
              alt="Mobile Interface - Ampel Red"
              className="items-center m-auto max-w-xs min-h-min lg:max-w-full lg:max-h-fit"
            />
            <h3 className="text-center font-bold leading-tight uppercase md:text-xl">
              <FormattedHTMLMessage id="homepage-mobile-ampel.mobile-ampel-red" />
            </h3>
            <p className="mt-4 text-sm md:text-lg 2xl:text-2xl font-light leading-relaxed text-center px-8">
              <FormattedHTMLMessage id="homepage-mobile-ampel.mobile-ampel-red-text" />
            </p>
          </Card>
        </Slider>

        {/* Quote Title*/}
        <div className="bg-white mx-auto py-10 ">
          <SplitMorningCoffeeTitle
            primaryTitle={
              <div
                className="text-center mx-auto py-3 
                             border-t-2 border-b-2 md:border-t-4 md:border-b-4 
                             text-xs md:text-xl px-5 mb-5 w-5/6 lg:w-3/6 sm:w-3/6"
              >
                <FormattedHTMLMessage id="homepage-mobile-ampel.ampel-split-title" />
              </div>
            }
          />
        </div>
      </div>
    )
  }
}
