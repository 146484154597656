import React from "react"

const HorizontalCardSlot = ({ primaryRow, secundaryRow }) => {
  return (
    <section className="xl:container mx-auto sm:flex-1 xl:flex xl:flex-row xl:flex-initial bg-white self-start">
      <div className="xl:w-1/2 xl:flex-row ">{primaryRow}</div>
      <div className="xl:w-1/2 xl:flex-row">{secundaryRow}</div>
    </section>
  )
}

export default HorizontalCardSlot
