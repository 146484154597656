import React from "react"

const MorningGreenTitle = ({
  firstRowTitle,
  secundRowTitle,
  tertiaryRowTitle,
}) => {
  return (
    <div className="xl:container mx-auto">
      <div
        className="text-center text-white uppercase font-bold tracking-wider 
          sm:w-full sm:text-xl md:text-3xl sm:text-center 2xl:text-4xl"
      >
        <h2 className=" w-max uppercase font-bold text-white text-left">
          {firstRowTitle}
        </h2>

        <h2 className=" my-0.5 md:my-2.5 xl:my-3 uppercase font-bold text-white text-left">
          {secundRowTitle}
        </h2>

        <h2 className="w-max uppercase font-bold text-white text-left ">
          {tertiaryRowTitle}
        </h2>
      </div>
    </div>
  )
}

export default MorningGreenTitle

// sm:text-lg md:text-4xl 2xl:text-5xl
// text-base sm:text-2xl md:text-3xl 2xl:text-5xl
