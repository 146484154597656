import React from "react"
import { FormattedHTMLMessage } from "gatsby-plugin-intl"
import Advantages from "../assets/homepage/05-vorteile/relution-air-vorteile.png"
// fragments import
import MorningGreenTitle from "./fragments/MorningGreenTitle"
import SplitMorningCoffeeTitle from "./fragments/SplitMorningCoffeeTitle"
import Card from "./fragments/Card"

const TableCardSection = () => {
  return (
    <section>
      <div className=" bg-bg_coffee relative block mx-auto xl:container">
        <div className="mx-auto">
          <img
            src={Advantages}
            alt="Relution air advantages"
            className="w-full h-auto"
          />
        </div>
        <div className="xl:container mx-auto absolute xxs:top-5">
          <div
            className="text-center font-bold tracking-wider sm:pt-6 px-5 sm:px-10
          sm:w-full sm:text-base md:text-3xl 2xl:text-5xl "
          >
            <MorningGreenTitle
              firstRowTitle={
                <span
                  className="bg-morning_green w-max px-2"
                  translate="no"
                  lang="de"
                >
                  <FormattedHTMLMessage id="homepage-advantages.relution-air-morning-green-title" />
                </span>
              }
              secundRowTitle={
                <span className="bg-morning_green w-max px-2">
                  <FormattedHTMLMessage id="homepage-advantages-overview.title-row-02" />
                </span>
              }
              tertiaryRowTitle={
                <span className="bg-morning_green w-max px-2">
                  <FormattedHTMLMessage id="homepage-advantages-overview.title-row-03" />
                </span>
              }
            />
          </div>
        </div>
        {/* table section */}
        <div className="xl:absolute mx-auto xl:container inset-x-0 bottom-0 lg:top-2/5 bg-opacity_white 2xl:px-20 xl:pt-10 ">
          <div className="grid xl:grid-cols-2 col-span-1 gap-x-10 xl:container mx-auto md:grid-cols-1 ">
            <Card className="xl:ml-10 xl:mr-7 xl:mx-0 ">
              <h3 className="bg-morning_green text-lg md:text-2xl font-bold leading-tight uppercase text-center text-white py-2">
                <FormattedHTMLMessage id="homepage-advantages-overview.title-luftsensor" />
              </h3>
              <ul className="text-center uppercase font-bold text-xs sm:text-sm">
                <li className="px-2 py-2 border-b-2 bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.luftsensor-row-01" />
                </li>
                <li className="px-2 py-2 border-b-2 bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.luftsensor-row-02" />
                </li>
                <li className="px-2 py-2 border-b-2 bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.luftsensor-row-03" />
                </li>
                <li className="px-2 py-2 border-b-2 bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.luftsensor-row-04" />
                </li>
                <li className="px-2 py-2 border-b-2 bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.luftsensor-row-05" />
                </li>
                <li className="px-2 py-2 border-b-2 bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.luftsensor-row-06" />
                </li>
                <li className="px-2 py-2 border-b-2 bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.luftsensor-row-07" />
                </li>
                <li className="px-2 py-2 border-b-2 bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.luftsensor-row-08" />
                </li>
                <li className="px-2 py-2  bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.luftsensor-row-09" />
                </li>
              </ul>
              <p className="pt-2 xxs:pb-5 xl:pb-0 text-center text-xs">
                <FormattedHTMLMessage id="homepage-advantages-overview.luftsensor-text" />
              </p>
            </Card>

            <Card className=" xl:mr-12 xl:ml-6 xl:mx-0 md:mt-2 lg:mt-0 md:text-center xl:text-left">
              <h3 className="bg-morning_green text-lg md:text-2xl  font-bold leading-tight uppercase text-center text-white py-2">
                <FormattedHTMLMessage id="homepage-advantages-overview.title-mobile-app" />
              </h3>
              <ul className="text-center uppercase font-bold text-xs sm:text-sm">
                <li className="px-2 py-2 border-b-2 bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.mobile-app-row-01" />
                </li>
                <li className="px-2 py-2 border-b-2 bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.mobile-app-row-02" />
                </li>
                <li className="px-2 py-2 border-b-2 bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.mobile-app-row-03" />
                </li>
                <li className="px-2 py-2 border-b-2 bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.mobile-app-row-04" />
                </li>
                <li className="px-2 py-2 border-b-2 bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.mobile-app-row-05" />
                </li>
                <li className="px-2 py-2 border-b-2 bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.mobile-app-row-06" />
                </li>
                <li className="px-2 py-2 border-b-2 bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.mobile-app-row-07" />
                </li>
                <li className="px-2 py-2 border-b-2 bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.mobile-app-row-08" />
                </li>
                <li className="px-2 py-2  bg-transparent_green  border-separate border border-opacity-25 border-opacity_white ">
                  <FormattedHTMLMessage id="homepage-advantages-overview.mobile-app-row-09" />
                </li>
              </ul>
            </Card>
          </div>
        </div>
      </div>

      {/* Quote title */}
      <div className=" bg-white xl:container mx-auto pt-5 ">
        <SplitMorningCoffeeTitle
          primaryTitle={
            <div
              className="mx-auto text-center py-3 border-t-2 border-b-2 md:border-t-4 md:border-b-4 
                            text-xs md:text-xl md:px-10 mb-5 lg:w-3/6 w-5/6"
            >
              <h3>
                <FormattedHTMLMessage id="homepage-advantages-overview.split-title" />
              </h3>
            </div>
          }
        />
      </div>
    </section>
  )
}

export default TableCardSection
